import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
//import { Helmet } from "react-helmet"

const CoursesPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
<Layout location="Courses" title={title}>
<Seo title="All Courses" />
  <article>
    <div>
      <StaticImage
        className="floaty-icon"
        placeholder="none"
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/course.png"
        width={50}
        height={50}
        quality={50}
        alt="Course icon"
      />
      <h1>Courses</h1>
      <ul className="tag-results">
        {group.map(course => (
          <li key={course.fieldValue}>
            <Link to={`/courses/${kebabCase(course.fieldValue)}/`}>
              {course.fieldValue} <span className="details">({course.totalCount})</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </article>
</Layout>
)

CoursesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default CoursesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { tags: { ne: "not-golf" } } }
      ) {
      group(field: frontmatter___title) {
        fieldValue
        totalCount
      }
    }
  }
`